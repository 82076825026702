import BaseModel from 'models/BaseModel'
import yup from 'utils/yup'

const defaultColor = '#FFFFFF'

class SettingModel extends BaseModel {
  constructor(
    colors = {
      confirmed: defaultColor,
      noShow: defaultColor,
      noStatus: defaultColor,
      booked: defaultColor,
      available: defaultColor,
      notAvailable: defaultColor,
    },
    hideBookedSlots = false,
    approx = { enabled: false, minutes: '' },
    autoConfirmBookings = false,
    autoConfirmMinutes = '',
    autoCancelBookings = false,
    autoCancelMinutes = '',
    sameDayBooking = true,
    bookingCloseTime = '',
    advancedBookingDays = '',
    editableDays = '',
    welcome = '',
    paymentMessage = '',
    sms = { enabled: false, templateId: '' },
    email = { enabled: false, templateId: '' },
    id = null
  ) {
    super()
    this.colors = colors
    this.hideBookedSlots = hideBookedSlots
    this.approx = approx
    this.autoConfirmBookings = autoConfirmBookings
    this.autoConfirmMinutes = autoConfirmMinutes
    this.autoCancelBookings = autoCancelBookings
    this.autoCancelMinutes = autoCancelMinutes
    this.sameDayBooking = sameDayBooking
    this.bookingCloseTime = bookingCloseTime
    this.advancedBookingDays = advancedBookingDays
    this.editableDays = editableDays
    this.welcome = welcome
    this.paymentMessage = paymentMessage
    this.sms = sms
    this.email = email
    this.id = id
  }

  static validationSchema = yup.object({
    welcome: yup.string().label('Welcome Message'),
    advancedBookingDays: yup
      .number()
      .required()
      .min(1)
      .transform((value) => (isNaN(value) ? undefined : value))
      .label('Max Advanced Booking Days'),
    sms: yup.object().shape({
      enabled: yup.boolean().label('Enabled'),
      templateId: yup
        .string()
        .label('Template')
        .when('enabled', (enabled, schema) => (enabled ? schema.required() : schema)),
    }),
    email: yup.object().shape({
      enabled: yup.boolean().label('Enabled'),
      templateId: yup
        .string()
        .label('Template')
        .when('enabled', (enabled, schema) => (enabled ? schema.required() : schema)),
    }),
    autoConfirmMinutes: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .label('Minute(s) After Booking Made')
      .when('autoConfirmBookings', (autoConfirmBookings, schema) => (autoConfirmBookings ? schema.required() : schema)),
    autoCancelMinutes: yup
      .number()
      .min(1)
      .label('Minute(s) After Booking Made')
      .transform((value) => (isNaN(value) ? undefined : value))
      .when('autoCancelBookings', (autoCancelBookings, schema) => (autoCancelBookings ? schema.required() : schema)),
    editableDays: yup
      .number()
      .min(1)
      .label('Allow To Edit Booking Day(s) Before')
      .transform((value) => (isNaN(value) ? undefined : value)),
  })

  toJSON() {
    return {
      colors: this.colors,
      openTime: this.openTime,
      closeTime: this.closeTime,
      advancedBookingDays: this.advancedBookingDays,
      sms: this.sms,
      email: this.email,
      id: this.id,
    }
  }
}

export default SettingModel
