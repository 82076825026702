import Booking from 'models/Booking'

const getObject = (booking) =>
  new Booking(
    booking.name,
    booking.email,
    booking.phone,
    booking.remark,
    booking.status,
    booking.slotTimetableId,
    booking.slot
  )

export default getObject
